<script setup lang="ts">

import PasswordlessRegistration from './components/PasswordlessRegistration.vue';

const route = useRoute()
const redirect = (route.query.redirect) as string

</script>

<template>
    <PasswordlessRegistration type="login" />

</template>

